<template>
    <div class="rounded-lg shadow-lg">
      <basic-information mode="edit" :company="company" />
    </div>
</template>
<script>
import { BCol, BRow } from "bootstrap-vue";
import basicInformation from "./components/company-basic-information.vue";
export default {
  data() {
    return {
      company: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        name: "",
        info: {
          industry: "",
          abn: "",
          acn: "",
          tfn: "",
          city: "",
          state: "",
        },
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getCompany();
    }
  },
  components: {
    BRow,
    BCol,
    basicInformation,
  },
  methods: {
    getCompany() {
      this.$store
        .dispatch("company/getCompany", this.$route.params.id)
        .then((response) => {
          this.company = response.data;
          if (this.company.info === null) {
            this.company.info = {
              industry: "",
              abn: "",
              acn: "",
              tfn: "",
              city: "",
              state: "",
            };
          }
        });
    },
  },
};
</script>
